import { Box, makeStyles, Typography } from "@material-ui/core"
import Img from "gatsby-image"
import React from "react"
import { DigicLink } from "../digic-link"
import Truncate from "react-truncate"

const useStyles = makeStyles(theme => ({
  container: {
    display: "grid",
    height: "100%",
    "&:hover": {
      filter: "brightness(130%)",
    },
    "& img": {
      transition: "all 800ms ease !important",
      filter: "brightness(0.7)",
    },
    "&:hover img": {
      transform: "scale(1.05)",
    },
    transition: "all 800ms ease",
  },
  layerBottom: {
    gridRow: 1,
    gridColumn: 1,
    display: "block",
    position: "relative",
  },
  layerTop: {
    position: "relative",
    gridRow: 1,
    gridColumn: 1,
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  title: {
    height: "130px",
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
    paddingBottom: "20px",
    background: "linear-gradient(360deg, #000d 0%, rgba(0, 0, 0, 0) 100%)",
  },
}))

export const WorkPreview = ({
  data: { id, name, type, thumbnail },
  previewPath,
}) => {
  const classes = useStyles()

  return (
    <DigicLink to={`/${previewPath}works/${id}`}>
      <Box className={classes.container}>
        <Box className={classes.layerBottom}>
          <Img
            fluid={thumbnail.imageFile.childImageSharp.fluid}
            style={{ height: "100%" }}
          />
        </Box>
        <Box className={classes.layerTop}>
          <Box className={classes.title}>
            <Box style={{ marginBottom: "2px" }}>
              <Truncate
                lines={2}
                ellipsis={<span>...</span>}
                style={{
                  fontFamily: "Geogrotesque",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "24px",
                  lineHeight: "1.2",
                  color: "#fff",
                }}
              >
                {name}
              </Truncate>
            </Box>
            <Typography variant="caption">{type}</Typography>
          </Box>
        </Box>
      </Box>
    </DigicLink>
  )
}
