import { useState } from "react";

export const useShowMoreByYear = ({ itemsByYear, defaultShownCount = 3, newItemsOnShowMore = 3 }) => {
    const allYearsCount = Object.keys(itemsByYear).length;
    const [additionalShownCount, setAdditionalShownCount] = useState(0);
    const shownCount = defaultShownCount + additionalShownCount;
    const showMore = () => setAdditionalShownCount(additionalShownCount + newItemsOnShowMore)
    const hasMore = (allYearsCount - shownCount) > 0

    const slicedItemsByYear = {}

    Object.keys(itemsByYear)
        .sort((a, b) => b - a)
        .slice(0, shownCount)
        .forEach(year => {
            slicedItemsByYear[year] = itemsByYear[year]
        }
    )

    return { slicedItemsByYear, showMore, hasMore }
}