import { Box, Button, makeStyles, Typography } from "@material-ui/core"
import _ from "lodash"
import React from "react"
import { useShowMoreByYear } from "../utils/useShowMoreByYear"
import { WorkPreview } from "./work-preview"

const useStyles = makeStyles(theme => ({
  workItem: {
    width: "100%",
    aspectRatio: "16/9",
  },
  grid: {
    display: "grid",
    gridGap: "8px",
    gridTemplateColumns: "1fr 1fr 1fr",
    [theme.breakpoints.down("xl")]: {
      gridTemplateColumns: "1fr 1fr 1fr",
    },
    [theme.breakpoints.down("lg")]: {
      gridTemplateColumns: "1fr 1fr 1fr",
    },
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
    width: "100%",
  },
  singleGrid: {
    display: "grid",
    gridGap: "8px",
    gridTemplateColumns: "1fr",
    width: "100%",
  },
  duoGrid: {
    display: "grid",
    gridGap: "8px",
    gridTemplateColumns: "1fr 1fr",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
}))

const WorksGrid = ({ data: works, slicedbyYear, previewPath = "" }) => {
  const classes = useStyles()
  const worksSortedById = works.sort((a, b) => {
    if (a.id < b.id) return 1
    if (a.id > b.id) return -1
    return 0
  })
  const worksByYear = _.groupBy(worksSortedById, work => work.year_of_creation)

  const { slicedItemsByYear, hasMore, showMore } = useShowMoreByYear({
    itemsByYear: worksByYear,
  })
  return (
    <>
      {slicedbyYear ? (
        <>
          {Object.keys(slicedItemsByYear)
            .sort((a, b) => b - a)
            .map(year => (
              <Box
                key={year}
                my={8}
                display="flex"
                alignItems="start"
                flexDirection="column"
                margin="auto"
                maxWidth="1440px"
              >
                <Typography variant="h1" style={{ marginBottom: "32px" }}>
                  <span
                    style={{
                      backgroundColor: "#f26522",
                      padding: "0 8px",
                    }}
                  >
                    {year}
                  </span>
                </Typography>
                {slicedItemsByYear[year].length === 1 ? (
                  <Box className={classes.singleGrid}>
                    {slicedItemsByYear[year].map(work => (
                      <Box key={work.id} className={classes.workItem}>
                        <WorkPreview previewPath={previewPath} data={work} />
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <div />
                )}
                {slicedItemsByYear[year].length === 2 ? (
                  <Box className={classes.duoGrid}>
                    {slicedItemsByYear[year].map(work => (
                      <Box key={work.id} className={classes.workItem}>
                        <WorkPreview previewPath={previewPath} data={work} />
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <div />
                )}
                {slicedItemsByYear[year].length > 2 ? (
                  <Box className={classes.grid}>
                    {slicedItemsByYear[year].map(work => (
                      <Box key={work.id} className={classes.workItem}>
                        <WorkPreview previewPath={previewPath} data={work} />
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <div />
                )}
              </Box>
            ))}
          <Box width="100%" display="flex" justifyContent="center" p={6}>
            {hasMore && (
              <Button variant="contained" onClick={showMore}>
                Show more
              </Button>
            )}
          </Box>
        </>
      ) : (
        <Box className={classes.duoGrid}>
          {worksSortedById.map(work => (
            <Box key={work.id} className={classes.workItem}>
              <WorkPreview previewPath={previewPath} data={work} />
            </Box>
          ))}
        </Box>
      )}
    </>
  )
}

export default WorksGrid
